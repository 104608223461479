import Cookies from "js-cookie";

try {
  const open_btn = document.querySelector("#open-sidebar-btn-js");
  const close_btn = document.querySelector("#close-sidebar-btn-js");
  let sidebar = document.querySelector(".sidebar-container");

  open_btn.addEventListener("click", () => {
    sidebar.classList.add("active");
  });
  close_btn.addEventListener("click", () => {
    sidebar.classList.remove("active");
  });
} catch (e) {}

try {
  document
    .querySelector("#close-notification-js")
    .addEventListener("click", () => {
      document.querySelector(".announcement-container").classList.add("close");
      Cookies.set("is-user-notified", true, { expires: 3, path: "" });
    });

  if (Cookies.get("is-user-notified") === "true") {
    document
      .querySelector(".announcement-container")
      .classList.add("permanent-close");
  } else {
    Cookies.set("is-user-notified", false, { expires: 3, path: "" });
  }
} catch (e) {
  console.error(e);
}
